/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Image, Button, PriceWrap, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-lzn1hz --full pb--60 pt--60" name={"wstęp"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/img-1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/img-1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/img-1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/img-1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/img-1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/img-1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/img-1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/img-1_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style='color: white'>Pańska 18, Warszawa</span>"}>
              </Subtitle>

              <Title className="title-box fs--86 lh--12 mt--02" content={"<span style='color: white'>Warsztat samochodowy, na którym możesz polegać</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--20 pt--60" name={"informacje-1"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":500}} content={"Wymiana opon"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":500}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/84/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/84/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/84/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/84/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/84/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/84/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--60" name={"cennik-1"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--3 pb--20 pt--20" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/84/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/84/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/84/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/84/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/84/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/84/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Wymiana opon"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Subtitle className="subtitle-box" content={"479 ,–"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/84/fullscreen_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/84/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/84/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/84/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/84/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/84/fullscreen_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Pomiar głębokości bieżnika"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Subtitle className="subtitle-box" content={"175 ,–"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/84/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/84/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/84/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/84/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/84/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/84/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Ustawienie geometrii"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Subtitle className="subtitle-box" content={"129 ,–"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"informacje-2"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>Wymiana opon</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>Pomiar głębokości bieżnika</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>Ustawienie geometrii</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>Wymiana oleju</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>Szlifowanie i polerowanie zarysowań</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>Naprawa przedniej szyby\n</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ee2hgq pb--80 pt--80" name={"recenzje"} style={{"backgroundColor":"var(--color-supplementary)"}} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/84/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 pb--20 pt--20" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <Title className="title-box" content={"<span style='color: white'>\"Najlepszy autoserwis w Radomiu \"\n</span>"}>
              </Title>

              <Text className="text-box" content={"<span style='color: white'>Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn2 btn-box--cColor2 btn-box--filling2" content={"Recenzja — Facebook (48)"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1kjqefg mb--20 pb--30 pt--80" name={"cennik-2"} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style='color: black'>Cennik<br>usług serwisowych </span>"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--60" name={"cennik-3"}>
          
          <ColumnWrap className="column__flex el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="mt--0 pb--0 pt--0">
              
              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Wymiana oleju"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"33,— zł"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Szlifowanie i polerowanie zarysowań"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"33,— zł"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Naprawa przedniej szyby\n"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"33,— zł"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Kontrola układu hamulcowego"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"33,— zł"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Mycie silnika"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"33,— zł"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Przegląd i diagnostyka ogólna"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"33,— zł"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--80" name={"informacje-3"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="mb--25" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/o/g/Ikonky/Auto-Moto/auto-moto-9.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":130}} srcSet={""} content={null} svgColor={"rgb(255,255,255)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Bezpłatne holowanie dla klientów"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mb--25" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/o/g/Ikonky/Auto-Moto/auto-moto-4.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":130}} srcSet={""} content={null} svgColor={"rgb(255,255,255)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Okresowe napełnianie i czyszczenie klimatyzacji"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mb--25" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/o/g/Ikonky/Auto-Moto/auto-moto-3.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":130}} srcSet={""} content={null} svgColor={"rgb(255,255,255)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Sezonowa wymiana opon z rabatem"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--80 pt--80" name={"informacje-4"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style='color: var(--color-dominant)'>Wykonujemy ponad 5000 napraw rocznie </span>"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"galeria"} layout={"l12"}>
          
          <ColumnWrap className="column__flex el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/84/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/84/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/84/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/84/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/84/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/84/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/84/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/84/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/84/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/84/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/84/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/84/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/84/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/84/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/84/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/84/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/84/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/84/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/84/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/84/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/84/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/84/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/84/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/84/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--80 pt--80" name={"recenzje-2"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box btn-box--cbtn2 btn-box--cColor2 btn-box--filling2 btn-box--right" content={"Recenzja — Facebook (48)"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"kontakt-1"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Autoserwis"}>
              </Subtitle>

              <Text className="text-box" content={"Marszałkowska 153<br> Warszawa 02-077<br>Polska"}>
              </Text>

              <Subtitle className="subtitle-box mt--16" style={{"maxWidth":300}} content={"Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Text className="text-box" content={"E-mail:"}>
              </Text>

              <Subtitle className="subtitle-box" content={"info@twoje-stronyy.com"}>
              </Subtitle>

              <Text className="text-box" content={"Kontakt telefoniczny"}>
              </Text>

              <Subtitle className="subtitle-box" content={"+48 797 811 2X0"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Otwarte"}>
              </Subtitle>

              <Text className="text-box" content={"ND — CZW"}>
              </Text>

              <Subtitle className="subtitle-box" content={"9:00 — 23:00"}>
              </Subtitle>

              <Text className="text-box" content={"PT — SOB"}>
              </Text>

              <Subtitle className="subtitle-box" content={"11:00 — 02:00"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style3 mt--0" name={"kontakt-2"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Brno"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--80 pt--80" name={"kontakt-3"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style='color: var(--color-dominant)'>Pańska 18, Warszawa</span>"}>
              </Title>

              <Title className="title-box fs--48 mt--02" content={"<span style='color: var(--color-dominant)'>+48 797 811 2X0</span>"}>
              </Title>

              <Title className="title-box fs--48 mt--02" content={"<span style='color: var(--color-dominant)'>info@twoje-stronyy.com</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":480}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}